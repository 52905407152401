//Auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";

// User details
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_STORES = "SET_USER_STORES";
export const SET_SELECTED_STORE = "SET_SELECTED_STORE";
export const SET_USER_LOCATIONS = "SET_USER_LOCATIONS";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_SALES_PERSONS = "SET_SALES_PERSONS";
export const SET_SELECTED_SALES_PERSON = "SET_SELECTED_SALES_PERSON";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_ISMANAGER = "SET_ISMANAGER";
export const SET_ISCORPORATE_USER = "SET_ISCORPORATE_USER";
export const UPDATE_HOME_LOCID_START = "UPDATE_HOME_LOCID_START";
export const UPDATE_HOME_LOCID_SUCCESS = "UPDATE_HOME_LOCID_SUCCESS";
export const UPDATE_HOME_LOCID_FAILED = "UPDATE_HOME_LOCID_FAILED";

// Stip List
export const SET_USER_STIP_LIST_SUCCESS = "SET_USER_STIP_LIST_SUCCESS";
export const SET_USER_STIP_LIST_FINISHED = "SET_USER_STIP_LIST_FINISHED";
export const SET_USER_STIP_LIST_START = "SET_USER_STIP_LIST_START";
export const SET_USER_STIP_LIST_FAILED = "SET_USER_STIP_LIST_FAILED";
export const CLEAR_USER_STIP_LIST = "CLEAR_USER_STIP_LIST";

//Leads
export const GET_LEADS_COUNTS = "GET_LEAD_COUNTS";
export const GET_LEADS_COUNTS_START = "GET_LEADS_COUNTS_START";
export const SET_LEADS_CATEGORY = "SET_LEAD_CATEGORY";
export const SET_LEADS_CATEGORY_FAILED = "SET_LEADS_CATEGORY_FAILED";
export const REFRESH_HOT_LIST = "REFRESH_HOT_LIST";
export const GET_LEADS_START = "GET_LEADS_START";
export const SET_LEADBOOK_CATEGORY_START = "SET_LEADBOOK_CATEGORY_START";
export const SET_APPOINTMENT_CATEGORY = "SET_APPOINTMENT_CATEGORY";
export const TOGGLE_HOTLIST_STATUS = "TOGGLE_HOTLIST_STATUS";
export const TOGGLE_FIRELIST_STATUS = "TOGGLE_FIRELIST_STATUS";
export const SET_IS_SHARED_LEADBOOK = "SET_IS_SHARED_LEADBOOK";
export const DEACTIVATE_CALLS_START = "DEACTIVATE_CALLS_START";
export const DEACTIVATE_CALLS_FAILED = "DEACTIVATE_CALLS_FAILED";
export const DEACTIVATE_CALLS_SUCCESS = "DEACTIVATE_CALLS_SUCCESS";
export const SET_SELECTED_LEADBOOK_TAB = "SET_SELECTED_LEADBOOK_TAB";
export const UPDATE_LEADBOOK_PAGINATION_MODEL = "UPDATE_LEADBOOK_PAGINATION_MODEL";
export const UPDATE_LEADBOOK_FILTER_MODEL = "UPDATE_LEADBOOK_FILTER_MODEL";
export const UPDATE_LEADBOOK_SORT_MODEL = "UPDATE_LEADBOOK_SORT_MODEL"
export const RESET_LEADBOOK_PAGINATION_FILTER_AND_SORT_MODELS = "RESET_LEADBOOK_PAGINATION_FILTER_AND_SORT_MODELS";

// Customer profile
export const SET_CUSTOMER_LOADING = "SET_CUSTOMER_LOADING";
export const SET_CUSTOMER_PROFILE = "SET_CUSTOMER_PROFILE";
export const SET_CUSTOMER_PROFILE_UPDATE_RESPONSE =
    "SET_CUSTOMER_PROFILE_UPDATE_RESPONSE";
export const SET_CUSTOMER_CALL_ACTION_HISTORY_RESPONSE =
    "SET_CUSTOMER_CALL_ACTION_HISTORY_RESPONSE";
export const SET_CUSTOMER_CALL_ACTION_HISTORY_LIST_RESPONSE =
    "SET_CUSTOMER_CALL_ACTION_HISTORY_LIST_RESPONSE";
export const TOGGLE_CUSTOMER_PROFILE = "TOGGLE_CUSTOMER_PROFILE";
export const RESET_CUSTOMER_PROFILE = "RESET_CUSTOMER_PROFILE";

// CO-BUYER profile
export const SET_CO_BUYER_PROFILE = "SET_CO_BUYER_PROFILE";
export const SET_CO_BUYER_PROFILE_UPDATE_RESPONSE =
    "SET_CO_BUYER_PROFILE_UPDATE_RESPONSE";
export const TOGGLE_CO_BUYER_PROFILE = "TOGGLE_CO_BUYER_PROFILE";
export const RESET_CO_BUYER_PROFILE = "RESET_CO_BUYER_PROFILE";

// ProgressBar
export const LOADING_IN_PROGRESS = "LOADING_IN_PROGRESS";
export const LOADING_COMPLETED = "LOADING_COMPLETED";

// Appointment
export const CUSTOMER_APPOINTMENT_STATUS = "CUSTOMER_APPOINTMENT_STATUS";
export const CUSTOMER_APPOINTMENT_TIME_UPDATE =
    "CUSTOMER_APPOINTMENT_TIME_UPDATE";
export const CUSTOMER_APPOINTMENT_NOTE_UPDATE =
    "CUSTOMER_APPOINTMENT_NOTE_UPDATE";
export const CUSTOMER_APPOINTMENT_CANCEL = "CUSTOMER_APPOINTMENT_CANCEL";
// search
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const SEARCH_CUSTOMER_INPROGRESS = "SEARCH_CUSTOMER_INPROGRESS";
export const REFERRAL_SEARCH_CUSTOMER = "REFERRAL_SEARCH_CUSTOMER";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

// journal
export const SET_CUSTOMER_MEMO = "SET_CUSTOMER_MEMO";
export const UPDATE_CUSTOMER_MEMO = "UPDATE_CUSTOMER_MEMO";
export const UPDATE_LEAD_WITH_HASMEMO = "UPDATE_LEAD_WITH_HASMEMO";
export const TOGGLE_EDIT_MEMO = "TOGGLE_EDIT_MEMO";
export const SET_ACTION_TAB_SELECTED = "SET_ACTION_TAB_SELECTED";
export const REMINDER_HISTORY_LIST_RESPONSE = "REMINDER_HISTORY_LIST_RESPONSE";
export const TOGGLE_EDIT_REMINDER = "TOGGLE_EDIT_REMINDER";
export const SET_MEMO_TAB_INDEX = "SET_MEMO_TAB_INDEX";

//sort
export const SORT_LEAD = "SORT_LEAD";
export const SORT_APPOINTMENT = "SORT_APPOINTMENT";

// events - Notifications
export const ACTIVE_NOTIFICATIONS = "ACTIVE_NOTIFICATIONS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const SET_PROFILE_TAB = "SET_PROFILE_TAB";
export const SET_JOURNAL_TYPE = "SET_JOURNAL_TYPE";
export const TEXTNOTIFICATIONS = "TEXT NOTIFICATIONS";
export const DELETE_TEXTNOTIFICATIONS = "DELETE_TEXTNOTIFICATIONS";
export const UNREAD_TEXTNOTIFICATIONS = "UNREAD_TEXTNOTIFICATIONS";
export const READ_TEXTNOTIFICATIONS = "READ_TEXTNOTIFICATIONS";
export const TEXTNOTIFICATIONS_FORMANAGER = "TEXTNOTIFICATIONS_FORMANAGER";
export const SET_IS_TEXT_INBOX_OPEN = "SET_IS_TEXT_INBOX_OPEN";
export const SET_IS_NOTIFICATION_INBOX_OPEN = "SET_IS_NOTIFICATION_INBOX_OPEN";

//Store Preference Value
export const SET_STOREPREFERENCE_VALUE = "SET_STOREPREFERENCE_VALUE";
export const SET_TEXTTABSTOREPREFERENCE_VALUE = "SET_TEXTTABSTOREPREFERENCE_VALUE";
export const SET_SHOPPERPROFILETABSTOREPREFERENCE_VALUE = "SET_SHOPPERPROFILETABSTOREPREFERENCE_VALUE";
export const SET_CRMCREDITBUREAUVIEWSTOREPREFERENCE_VALUE = "SET_CRMCREDITBUREAUVIEWSTOREPREFERENCE_VALUE";
export const SET_COBUYERACTIONSSTOREPREFERENCE_VALUE = "SET_COBUYERACTIONSSTOREPREFERENCE_VALUE"
export const SET_CRMVIEWOLAFSCOREPREFERENCE_VALUE = "SET_CRMVIEWOLAFSCOREPREFERENCE_VALUE";

// Sales Reminders
export const GET_SALES_REMINDERS = "GET_SALES_REMINDERS";
export const SET_CATEGORY_TYPE = "SET_CATEGORY_TYPE";

//Stip Email Items
export const GET_EMAIL_ITEMS = "GET_EMAIL_ITEMS";
export const GET_EMAIL_ITEMS_FAIL = "GET_EMAIL_ITEMS_FAIL";

// Manager
export const GET_TOP_LEVEL_METRICS_START = "GET_TOP_LEVEL_METRICS_START";
export const GET_TOP_LEVEL_METRICS_SUCCESS = "GET_TOP_LEVEL_METRICS_SUCCESS";
export const GET_TOP_LEVEL_METRICS_FAILED = "GET_TOP_LEVEL_METRICS_FAILED";
export const GET_UNASSIGNED_LEADS_START = "GET_UNASSIGNED_LEADS_START";
export const GET_UNASSIGNED_LEADS_SUCCESS = "GET_UNASSIGNED_LEADS_SUCCESS";
export const GET_UNASSIGNED_LEADS_FAILED = "GET_UNASSIGNED_LEADS_FAILED";
export const POST_UNASSIGNED_LEADS_SUCCESS = "POST_UNASSIGNED_LEADS_SUCCESS";
export const POST_UNASSIGNED_LEADS_FAILED = "POST_UNASSIGNED_LEADS_FAILED";

// Manager - sales person goals
export const CANCEL_GOALS_EDIT = "CANCEL_GOALS_EDIT";
export const START_GOALS_EDIT = "START_GOALS_EDIT";
export const POST_GOALS_SUCCESS = "POST_GOALS_SUCCESS";
export const POST_GOALS_START = "POST_GOALS_START";
export const POST_GOALS_FAILED = "POST_GOALS_FAILED";
export const GET_SALES_PERSON_GOAL_ITEMS_START =
    "GET_SALES_PERSON_GOAL_ITEMS_START";
export const GET_SALES_PERSON_GOAL_ITEMS_FAILED =
    "GET_SALES_PERSON_GOAL_ITEMS_FAILED";
export const GET_SALES_PERSON_GOAL_ITEMS_SUCCESS =
    "GET_SALES_PERSON_GOAL_ITEMS_SUCCESS";

// Sales Person
export const GET_SALES_PERSON_GOALS_OPEN = "GET_SALES_PERSON_GOALS_OPEN";
export const GET_SALES_PERSON_GOALS_CLOSE = "GET_SALES_PERSON_GOALS_CLOSE";
export const GET_SALES_PERSON_GOALS_START = "GET_SALES_PERSON_GOALS_START";
export const GET_SALES_PERSON_GOALS_FAILED = "GET_SALES_PERSON_GOALS_FAILED";
export const GET_SALES_PERSON_GOALS_SUCCESS = "GET_SALES_PERSON_GOALS_SUCCESS";
export const SET_PRESTOREPREFERENCE_VALUE = "SET_PRESTOREPREFERENCE_VALUE";
export const GET_CRM_METRICS_GOALS_START = "GET_CRM_METRICS_GOALS_START";
export const GET_CRM_METRICS_GOALS_SUCCESS = "GET_CRM_METRICS_GOALS_SUCCESS";
export const GET_CRM_METRICS_GOALS_FAILED = "GET_CRM_METRICS_GOALS_FAILED";

// Customer - get discover notes
export const GET_SYSTEM_NOTES_START = "GET_SYSTEM_NOTES_START";
export const GET_SYSTEM_NOTES_SUCCESS = "GET_SYSTEM_NOTES_SUCCESS";
export const GET_SYSTEM_NOTES_FAILED = "GET_SYSTEM_NOTES_FAILED";
export const GET_MERGED_CUSTNUM_START = "GET_MERGED_CUSTNUM_START";
export const GET_MERGED_CUSTNUM_SUCCESS = "GET_MERGED_CUSTNUM_SUCCESS";
export const GET_MERGED_CUSTNUM_FAILED = "GET_MERGED_CUSTNUM_FAILED";
export const GET_SANUM_FROM_CUSTNUM_START = "GET_SANUM_FROM_CUSTNUM_START";
export const GET_SANUM_FROM_CUSTNUM_SUCCESS = "GET_SANUM_FROM_CUSTNUM_SUCCESS";
export const GET_SANUM_FROM_CUSTNUM_FAILED = "GET_SANUM_FROM_CUSTNUM_FAILED";
// Lead - Revert Lead
export const REVERT_LEAD_START = "REVERT_LEAD_START";
export const REVERT_LEAD_TODAY_SUCCESS = "REVERT_LEAD_TODAY_SUCCESS";
export const REVERT_LEAD_SUCCESS = "REVERT_LEAD_SUCCESS";
export const REVERT_LEAD_FAILED = "REVERT_LEAD_FAILED";
export const DELETE_REVERT_LEAD_SUCCESS = "DELETE_REVERT_LEAD_SUCCESS";

//Referral
export const GET_REFERRED_BY_RESPONSE_START = "GET_REFERRED_BY_RESPONSE_START";
export const GET_REFERRED_BY_RESPONSE_SUCCESS =
    "GET_REFERRED_BY_RESPONSE_SUCCESS";
export const GET_REFERRED_BY_RESPONSE_FAILED =
    "GET_REFERRED_BY_RESPONSE_FAILED";

// OLAF
export const GET_OLAF_APPLICATION = "GET_OLAF_APPLICATION";
export const GET_OLAF_APPLICATION_FAILED = "GET_OLAF_APPLICATION_FAILED";

// OLAF - Resubmit to queue
export const RESUBMIT_TO_QUEUE_START = "RESUBMIT_TO_QUEUE_START";
export const RESUBMIT_TO_QUEUE_SUCCESS = "RESUBMIT_TO_QUEUE_SUCCESS";
export const RESUBMIT_TO_QUEUE_FAILED = "RESUBMIT_TO_QUEUE_FAILED";

// OLAF - GET UW Status
export const GET_UW_STATUS_START = "GET_UW_STATUS_START";
export const GET_UW_STATUS_SUCCESS = "GET_UW_STATUS_SUCCESS";
export const GET_UW_STATUS_FAILED = "GET_UW_STATUS_FAILED";

// VehicleLoanDetails
export const GET_VEHICLE_LOAN_DETAILS_START = "GET_VEHICLE_LOAN_DETAILS_START";
export const GET_VEHICLE_LOAN_DETAILS_SUCCESS =
    "GET_VEHICLE_LOAN_DETAILS_SUCCESS";
export const GET_VEHICLE_LOAN_DETAILS_FAILED =
    "GET_VEHICLE_LOAN_DETAILS_FAILED";

//LeadsForMetricsCategory
export const GET_LEADS_FOR_METRICS_CATEGORY_START =
    "GET_LEADS_FOR_METRICS_CATEGORY_START";
export const GET_LEADS_FOR_METRICS_CATEGORY_SUCCESS =
    "GET_LEADS_FOR_METRICS_CATEGORY_SUCCESS";
export const GET_LEADS_FOR_METRICS_CATEGORY_FAILED =
    "GET_LEADS_FOR_METRICS_CATEGORY_FAILED";
export const SET_CURRENT_LEAD_TYPE_FOR_METRICS =
    "SET_CURRENT_LEAD_TYPE_FOR_METRICS";
export const SET_CRM_METRICS_TAB_VALUE = "SET_CRM_METRICS_TAB_VALUE";

// Reassign Leads
export const REASSIGN_LEADS_START = "REASSIGN_LEADS_START";
export const REASSIGN_LEADS_SUCCESS = "REASSIGN_LEADS_SUCCESS";
export const REASSIGN_LEADS_FAILED = "REASSIGN_LEADS_FAILED";
export const SELECT_ALL_REASSIGN = "SELECT_ALL_REASSIGN";
export const SELECT_ALL_REASSIGN_START = "SELECT_ALL_REASSIGN_START";
export const SELECT_SINGLE_LEAD_REASSIGN = "SELECT_SINGLE_LEAD_REASSIGN";
export const SELECT_ALL_BUTTON_STATUS = "SELECT_ALL_BUTTON_STATUS";

//Filter Leadbook
export const SET_LEADBOOK_FILTER_STATUS = "SET_LEADBOOK_FILTER_STATUS";
export const SET_LEADBOOK_FILTER_CRITERIAS = "SET_LEADBOOK_FILTER_CRITERIAS";

// Lead - Generate Pass Code
export const GENERATE_PASSCODE_START = "GENERATE_PASSCODE_START";
export const GENERAGE_PASSCODE_SUCCESS = "GENERAGE_PASSCODE_SUCCESS";
export const GENERATE_PASSCODE_FAILED = "GENERATE_PASSCODE_FAILED";

export const EXPORT_DATA = "EXPORT_DATA";

export const LEAD_EXPORT_STARTS = "LEAD_EXPORT_STARTS";
export const LEAD_EXPORT_FAILED = "LEAD_EXPORT_FAILED";
export const LEAD_EXPORT_SUCCESS = "LEAD_EXPORT_SUCCESS";

export const CANCEL_EXPORT_DATA = "CANCEL_EXPORT_DATA";
export const CANCEL_EXPORT_DATA_FAILED = "CANCEL_EXPORT_DATA_FAILED";

export const LEAD_FILEDOWNLOAD_STARTS = "LEAD_FILEDOWNLOAD_STARTS";
export const LEAD_FILEDOWNLOAD_FAILED = "LEAD_FILEDOWNLOAD_FAILED";
export const LEAD_FILEDOWNLOAD_SUCCESS = "LEAD_FILEDOWNLOAD_SUCCESS";

export const SET_CONTACT_PREFERENCE_STARTS = "SET_CONTACT_PREFERENCE_STARTS";
export const SET_CONTACT_PREFERENCE_FAILED = "SET_CONTACT_PREFERENCE_FAILED";
export const CONTACT_PREFERENCE_SUCCESS = "CONTACT_PREFERENCE_SUCCESS";

export const GET_CONTACT_PREFERENCE_STARTS = "GET_CONTACT_PREFERENCE_STARTS";

export const SET_TODO_FILTER_CRIT = "SET_TODO_FILTER_CRIT";

// Application
export const GET_APPLICATIONDATA_STARTS = "GET_APPLICATIONDATA_STARTS";
export const GET_APPLICATIONDATA_FAILED = "GET_APPLICATIONDATA_FAILED";
export const GET_APPLICATIONDATA_SUCCESS = "GET_APPLICATIONDATA_SUCCESS";

export const GET_APPLICATIONDATACOUNT_STARTS = "GET_APPLICATIONDATACOUNT_STARTS";
export const GET_APPLICATIONDATACOUNT_FAILED = "GET_APPLICATIONDATACOUNT_FAILED";
export const GET_APPLICATIONDATACOUNT_SUCCESS = "GET_APPLICATIONDATACOUNT_SUCCESS";
export const SET_APPLICATIONLIST = "SET_APPLICATIONLIST";
export const SET_APPLICATIONSEARCHCRITERIA = "SET_APPLICATIONSEARCHCRITERIA";
export const SET_APPLICATIONFILTERCRITERIA = "SET_APPLICATIONFILTERCRITERIA";
export const RESET_APPLICATIONFILTERCRITERIA = "RESET_APPLICATIONFILTERCRITERIA";

export const SET_APPLICATIONGRID_SPINNER = "SET_APPLICATIONGRID_SPINNER";
export const UPDATE_APPLICATION_PAGINATION_MODEL = "UPDATE_APPLICATION_PAGINATION_MODEL";
export const UPDATE_APPLICATION_SORT_MODEL = "UPDATE_APPLICATION_SORT_MODEL";
export const RESET_APPLICATION_SORT_MODEL = "RESET_APPLICATION_SORT_MODEL";

//Metadata
export const GET_CRM_VERSION = "GET_CRM_VERSION";
export const GET_LEAD_VERSION = "GET_LEAD_VERSION";


//Texting
export const GET_TEXT_HISTORY = "GET_TEXT_HISTORY";
export const SET_TEXT_HISTORY = "SET_TEXT_HISTORY";
export const ADD_MESSAGE_TO_TEXT_HISTORY = "ADD_MESSAGE_TO_TEXT_HISTORY";
export const CLEAR_TEXT_HISTORY = "CLEAR_TEXT_HISTORY";
export const TEXT_NOTIFICATION_SUBSCRIPTION = "TEXT_NOTIFICATION_SUBSCRIPTION";


//Loader
export const SET_LOADER = 'SET_LOADER'
export const GET_LOADER = 'GET_LOADER'

//Segment
export const GET_TRAITS = 'GET_TRAITS'
export const GET_EVENTS = 'GET_EVENTS'
export const GET_VEHICLES = 'GET_VEHICLES'
export const CLEAR_SHOPPER_PROFILE = 'CLEAR_SHOPPER_PROFILE'
export const IS_FETCHING_DATA = 'IS_FETCHING_DATA'
export const NO_DATA_FOR_USER = 'NO_DATA_FOR_USER'
