export const getEnvironment = () => {
  let env = 'prod';
  const locationHref = window.location.href;
  if (locationHref.includes('localhost')) {
    env = "debug";
  }
  if (locationHref.includes('dev-')) {
    env = 'dev';
  }
  if (locationHref.includes('qa-')) {
    env = 'qa';
  }
  if (locationHref.includes('stage-')) {
    env = 'stage';
  }
  return env;
};
