import getClient from './client/client';

const baseLeadUrl = '/api';
const version = '/health/version';
const leadsVersion = '/health/version/leadapi';
const getAppInsightsEnvValues = '/Environment/AppInsights';

let userName = null;
let client = getClient(baseLeadUrl);

const ApiClient = {
    async login(username) {
        //TODO: Make Api call to actually logging and store auth token here
        userName = username;
    },

    async logout() {
        userName = null;
    },

    async getAppVersion() {
        const result = await client.get(version);

        return result.data;
    },

    async getLeadsApiVersion() {
        const result = await client.get(leadsVersion);
        return result.data;
    },

    async getAppInsightsEnvironmentValue() {
        const result = await client.get(getAppInsightsEnvValues);
        if (result && result.data) return result.data;
        return null;
    }
};

export { ApiClient, userName };
