import axios from 'axios';

import CookieAuth from '../lib/APIResponse/CookieAuth';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/AuthTokenNames';


const getClient = (baseUrl = null) => {

    const authenticateApiUrl = "/auth/AuthenticateUserByGoogleCode"
    const refreshTokenUrl = "/auth/RefreshToken"

    const options = {
        baseURL: baseUrl
    };

    const client = axios.create(options);

    // Request
    client.interceptors.request.use(
        config => {
            const token = new CookieAuth().getCookieValue('jwt');
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            config.headers['Content-Type'] = 'application/json';
            config.headers['Accept'] = 'application/json, text/plain, */*';
            // TODO: check if have any lang saved in localStorage, if not browser lang or default
            config.headers['Accept-Language'] = 'en';
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );


    client.interceptors.response.use(config => {
        return config;
    }, async error => {
        const originalConfig = error.config;
        if (!originalConfig.url.includes(authenticateApiUrl) && error.response) {
            // Access Token was expired
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {

                    const refreshToken = new CookieAuth().getCookieValue(REFRESH_TOKEN);

                    const response = await client.post(refreshTokenUrl, {
                        refreshToken: refreshToken,
                        timeZoneOffsetInMin: new Date().getTimezoneOffset()
                    });
                    if (response) {
                        new CookieAuth().setCookieValue(ACCESS_TOKEN, response.data.token, {
                            path: '/',
                            maxAge: parseInt(response.data.expirationTime)
                        });

                        new CookieAuth().setCookieValue(REFRESH_TOKEN, response.data.refreshToken, {
                            path: '/',
                            maxAge: parseInt(response.data.refreshTokenExpirationTime)
                        });

                        new CookieAuth().setCookieValue('loggedIn', true, {
                            maxAge: parseInt(response.data.expirationTime),
                            path: '/'
                        });
                    } else {
                        window.location.href = "/";
                        return Promise.reject(error);
                    }
                    return client(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
    })

    return client;
};

export default getClient