import * as actionTypes from '../constants/actionTypes'

const initialState = {
  scheduledDate: '', // from API
  leadType: '', // from API
  leadTypeCode: '',
  contactAttempts: 0, // from API
  lastContacted: '', // from API
  status: '', // from API
  categoryType: '', // from API value 2
  disabled: true,
  name: '',
  firstName: '', // from API
  lastName: '', // from API
  middleName: '',
  email: '', // from API
  zipCode: '',
  city: '',
  suffix: '',
  labelWidth: 0,
  phoneNumberDetails: [],
  checked: true,
  track: '',
  address: '',
  ssn: '',
  applicationType: 'Co-Buyer',
  adSourceCategory: '',
  adSourceDetailDescription: '',
  adSourceDetailCode: '',
  saNum: 0,
  history: {
    communication: [
    ]
  }
};

const CoBuyer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_PROFILE:
      return initialState;
    case actionTypes.SET_CO_BUYER_PROFILE: {
      return { ...state, ...action.payload };
    }
    case actionTypes.RESET_CO_BUYER_PROFILE:
      return {};
    case actionTypes.SET_CUSTOMER_PROFILE_UPDATE_RESPONSE:
      return { ...state, updateResponse: action.payload };
    case actionTypes.SET_CO_BUYER_PROFILE_UPDATE_RESPONSE:
      return { ...state, updateResponse: action.payload };
    case actionTypes.LOADING_IN_PROGRESS:
      return { ...state, updateResponse: {} };
    default:
      return state;
  }
};

export default CoBuyer;
