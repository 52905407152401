import {
    LOADING_COMPLETED,
    LOADING_IN_PROGRESS,
    SET_CUSTOMER_PROFILE,
    SET_CUSTOMER_PROFILE_UPDATE_RESPONSE,
    SET_CO_BUYER_PROFILE_UPDATE_RESPONSE
} from "../constants/actionTypes";

const initialState = {
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_IN_PROGRESS:
          return {
            ...state,
            loading: true
          }
        case LOADING_COMPLETED:
          return {
            ...state,
            loading: false
          }
        case SET_CUSTOMER_PROFILE:
          return {
            ...state,
            loading: false
          }
        case SET_CUSTOMER_PROFILE_UPDATE_RESPONSE:
          return {
            ...state,
            loading: false
          }
        case SET_CO_BUYER_PROFILE_UPDATE_RESPONSE:
          return {
            ...state,
            loading: false
          }
        default:
          return initialState;
    }
};

export default reducer;
