import {
    SET_USER_STIP_LIST_START,
    SET_USER_STIP_LIST_FINISHED,
    SET_USER_STIP_LIST_FAILED,
    SET_USER_STIP_LIST_SUCCESS,
    CLEAR_USER_STIP_LIST,
    GET_EMAIL_ITEMS,
    GET_EMAIL_ITEMS_FAIL
} from '../constants/actionTypes';

const initialState = {
    data: [],
    emailItems: {},
    errors: '',
    loading: false
};

export const stipListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_STIP_LIST_START:
            return {
                ...state,
                loading: true
            };
        case SET_USER_STIP_LIST_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case SET_USER_STIP_LIST_FINISHED:
            return {
                ...state,
                loading: false
            };
        case SET_USER_STIP_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case CLEAR_USER_STIP_LIST:
            return {
                ...state,
                data: [],
                loading: false
            };
        case GET_EMAIL_ITEMS:
            return {
                ...state,
                emailItems: action.payload,
                loading: false,
                errors: ''
            };
        case GET_EMAIL_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        default:
            return state;
    }
};
