import { DONE, CURRENT, NOT_DONE } from './CallStatus';

// Google Auth

// JDByrider.com
export const CLIENT_ID =
    '727150925269-akp3s02m9tenn6a71gn6qi1343007itu.apps.googleusercontent.com'; //'650199803100-6e06k1tsdn4e0nakojau4g990nurhsdp.apps.googleusercontent.com';

// JDBYRIDER.ORG
// export const CLIENT_ID = '232224507429-ao3i30o0qshev3u0aadpdmsvgc5tsbah.apps.googleusercontent.com';

// Categories
export const HOTLIST_INDEX = 10;
export const DEFAULT_CATEGORY = 2;
export const DEFAULT_APPOINTMENT_CATEGORY = 5;

export const REFERRAL_CONTACT_SOURCE = 'Customer Referral';
export const APPLICATION_TYPE_CO_APPLICANT = 'Co-Applicant';
export const APPLICATION_TYPE_CO_BUYER = 'Co-Buyer';
export const APPLICATION_TYPE_INDIVIDUAL = 'Individual';

export const BUYER_APPLICATION_TYPES = [{ name: APPLICATION_TYPE_INDIVIDUAL }];
export const CO_BUYER_APPLICATION_TYPES = [
    { name: APPLICATION_TYPE_CO_BUYER },
    { name: APPLICATION_TYPE_CO_APPLICANT }
];
export const REFERRAL_APPLICATION_TYPES = [
    { name: APPLICATION_TYPE_CO_APPLICANT },
    { name: APPLICATION_TYPE_CO_BUYER },
    { name: APPLICATION_TYPE_INDIVIDUAL }
];
export const CO_BUYER_PAGE_NUMBER = 2;
export const BUYER_PAGE_NUMBER = 1;

export const BEST_TIME_TO_CALL = [
    { name: '9am-12pm', trackStatus: 1 },
    { name: '12pm-3pm', trackStatus: 2 },
    { name: '3pm-6pm', trackStatus: 3 },
    { name: '6pm-9pm', trackStatus: 4 }
];

export const LEAD_TYPE_KEY_INTERNAL = 'Internal';
export const LEAD_TYPE_CODE_INTERNAL = 'N';
export const LEAD_TYPE_CODE_PHONEUP = 'P';
export const LEAD_TYPE_CODE_LOTUP = 'L';
export const LEADTYPES = [
    { key: 'Lot Up', value: LEAD_TYPE_CODE_LOTUP },
    { key: 'Internal', value: LEAD_TYPE_CODE_INTERNAL },
    { key: 'Phone Up', value: LEAD_TYPE_CODE_PHONEUP }
];

export const RESETABLE_LEADTYPES = [
    { source: 'Byrider Lead Exchange', target_lead_type: 'Internal', target_type_code: 'N' },
];

export const CONTACTSOURCE_LOTUP_AND_PHONEUP = [
    { key: 'Internet', value: '6' },
    { key: 'Location', value: 'L' },
    { key: 'Previous Applicant', value: '8' },
    { key: 'TV', value: '9' },
    { key: 'Radio', value: 'R' },
    { key: 'Store Event', value: '0' }
];
export const CONTACTSOURCE_INTERNAL = [
    { key: 'Business Referral', value: 'F' },
    { key: 'Customer Referral', value: 'X' },
    { key: 'Preferred Employer', value: 'Q' },
    { key: 'Repeat', value: 'U' },
    { key: 'Sales Associate Generated', value: '$' },
    { key: 'Internet', value: '6' }
];

export const REFERRAL_LEADTYPES = [
    { key: 'Internal', value: LEAD_TYPE_CODE_INTERNAL }
];

export const INTERNETTYPES = [
    { value: 'GOJDB' },
    { value: 'BYRIDER LEAD EXCHANGE' },
    { value: 'PPC' }
];

export const SUFFIXES = [
    { value: 'I' },
    { value: 'II' },
    { value: 'III' },
    { value: 'IV' },
    { value: 'V' },
    { value: 'JR' },
    { value: 'SR' }
];

export const TRACK_STATUS = [
    { name: 'Appointment', trackStatus: DONE },
    { name: 'STIPs', trackStatus: DONE },
    { name: 'PRE', trackStatus: CURRENT },
    { name: 'Application', trackStatus: NOT_DONE },
    { name: 'Decisioned', trackStatus: NOT_DONE },
    { name: 'Sold', trackStatus: NOT_DONE }
];

export const STATE_NAMES = [
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'Alberta', value: 'AB' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'Delaware', value: 'DE' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Ontario', value: 'ON' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Puerto Rico', value: 'PR' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Washington', value: 'WA' },
    { name: 'Washington, DC', value: 'DC' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' }
];

export const NOTIFICATION_TYPE = [
    { name: 'all', value: 'All', index: -1 },
    { name: 'prospect_assigned', value: 'Prospect Assigned', index: 0 },
    { name: 'missed_appointment', value: 'Missed Appointment', index: 1 },
    { name: 'reminder', value: 'Reminder', index: 2 },
    { name: 'prospect_not_contacted', value: 'Prospect Not Contacted', index: 3 }
];

export const PIPELINE_REASONS = [
    { key: 'N/A', value: 'N/A' },
    { key: 'Dead Deal', value: 'Dead Deal' },
    { key: 'Do Not Call', value: 'Do Not Call' },
    { key: 'Bought Somewhere Else', value: 'Bought Somewhere Else' },
    { key: 'On a Pathway', value: 'On a Pathway' },
    { key: 'PRE-Reject', value: 'PRE-Reject' }
];

export const CUSTOMER_TYPE_BUYER = 'buyer';
export const CUSTOMER_TYPE_CO_BUYER = 'cobuyer';
export const ZIPCODE_MAX_LENGTH = 5;
export const PHONE_NUMBER_MAX_LENGTH = 10;
export const SSN_TEXT_LENGTH = 9;
export const TEXT_FIELD_MAX_LENGTH = 50; // firstName, lastName, address etc.
export const MIDDLE_NAME_MAXLENGTH = 30;
export const SUFFIX_FIELD_MAX_LENGTH = 3;
export const MASK_CHAR = '*';
export const MASK_SEPERATOR_CHAR = '-';
export const LOGOUT_TIME_FIVE_MINUTE = 1000 * 60 * 5; //60 *
export const IDLE_TIME_MESSAGE = 1000 * 60 * 45;
export const NOTE_FIELD_MAX_LENGTH = 1000;
export const TEXT_MESSAGE_FIELD_MAX_LENGTH = 300;
export const CRM_UNDERWRITING_TAB = 'CRMUnderwritingTab';
export const PRE_STOREPREFERENCE = 'CRM PRE tab';

export const TEXT_STOREPREFERENCE = 'CRMDisplayTextTab';
export const OPT_IN_WELCOME_TEXT = 'Thank you for your interest in Byrider! Your phone number is not subscribed to receive SMS messages. Reply START to resubscribe. Reply STOP to unsubscribe. Msg&Data Rates May Apply.';
export const OPT_IN_TEXT = 'Reply START to resubscribe to texts from this Byrider number. Reply STOP to unsubscribe. Msg&Data Rates May Apply.';
export const OPT_OUT_TEXT = 'Reply STOP to unsubscribe and no longer receive messages from this Byrider number. Reply START to resubscribe. Msg&Data Rates May Apply.';
export const WELCOME_MESSAGE_TEXT = 'Thank you for your interest in Byrider! You have successfully been subscribed to sales messages from your local Byrider';
export const SHOPPERPROFILE_TAB_STOREPREFERENCE = 'CRMDisplayShopperProfileTab';
export const COBUYERACTIONS_STOREPREFERENCE = 'CRMCoBuyerSwapRemoveEnabled';
export const CRMCREDITBUREAUVIEW_STOREPREFERENCE = 'CRMCreditBureauView';
export const CRMVIEWOLAFSCORE_STOREPREFERENCE = 'CrmViewOlafScore';

export const SUFFIX_NAMES = [
    { key: '0', description: '' },
    { key: '1', description: 'I' },
    { key: '2', description: 'II' },
    { key: '3', description: 'III' },
    { key: '4', description: 'IV' },
    { key: '5', description: 'V' },
    { key: 'J', description: 'JR' },
    { key: 'S', description: 'SR' }
];
export const FIN_INTERVAL_CODE = [
    { key: 'B', description: 'Bi-Weekly', AnnualIntervalAmt: 26 },
    { key: 'M', description: 'Monthly', AnnualIntervalAmt: 12 },
    { key: 'S', description: 'Semi-Monthly', AnnualIntervalAmt: 24 },
    { key: 'W', description: 'Weekly', AnnualIntervalAmt: 52 }
];
export const JOB_TYPE_CODE = [
    { key: 'A', description: 'Full Time' },
    { key: 'B', description: 'Part Time' },
    { key: 'C', description: 'Seasonal' },
    { key: 'D', description: 'Social Security (SSI/SSD)' },
    { key: 'E', description: 'Not Working' },
    { key: 'T', description: 'Temporary' },
    { key: 'U', description: 'Unemployment' },
    { key: 'R', description: 'Retirement' }
];
export const OTHER_INCOME = [
    { key: 0, description: '' },
    { key: 2, description: '2nd Job' },
    { key: 6, description: 'Child Support' },
    { key: 8, description: 'Military' },
    { key: 9, description: 'Other' },
    { key: 10, description: 'S.S.I.' },
    { key: 41, description: 'Tips' }
];
export const YES_NO = [
    { key: '', name: '' },
    { key: 'No', name: 'No' },
    { key: 'Yes', name: 'Yes' }
];
export const CURRENCY_REGEX =
    '(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|\\d+)?(\\.\\d{1,2})?$';
export const CURRENCY_VALIDATOR = 'matchRegexp:' + CURRENCY_REGEX;
export const PRIMARY_DRIVER = [
    { key: '', description: 'Please Select...' },
    { key: 'B', description: 'Buyer' },
    { key: 'C', description: 'CoBuyer' },
    { key: 'O', description: 'Other' }
];
export const BANK_RUPTCY_LEFT = [
    { key: '', description: '' },
    { key: '0', description: '< 6 months' },
    { key: '1', description: '7-12 months' },
    { key: '2', description: '13-24 months' },
    { key: '3', description: '25+ months' }
];
export const BANK_RUPTCY_TYPE = [
    { key: '', description: '' },
    { key: '7', description: 'Chapter 7' },
    { key: '13', description: 'Chapter 13' }
];
export const PRE_QUALIFICATION_SUCCESS = 'Customer has qualified to proceed';
export const PRE_QUALIFICATION_FAILURE =
    'Customer has NOT qualified to proceed';
export const PRE_QUALIFICATION_ERROR = 'Evaluation failed! Please try again.';
export const PRE_INFORMATION_SAVE_FAILURE = 'PreInformation not saved!';
export const PRE_INFORMATION_SAVE_SUCCESS = 'PreInformation has been saved.';
export const PRE_TAB_ACCESS_DENIED =
    'Access Denied. Your location is not setup to do Interview Questions';
export const SCORING_SCREEN_VALUES = {
    Phantom: 2,
    Trinity: 3
};
export const BUTTON_COLOR_RED = '#f44336';
export const BUTTON_COLOR_GREEN = '#36b37e';
export const BUTTON_COLOR_BLUE = '#2168B6';

export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const OlafDecisionTypes =
    [
        "approved",
        "denied",
        "more_info",
        "unable_to_score",
        "abandoned"
    ];

export const APPLICATION_STATUS = [
    { key: 'All', value: 'All' },
    { key: 'Abandoned', value: 'Abandoned' },
    { key: 'more_info', value: 'More Info' },
    { key: 'unable_to_score', value: 'Unable to Score' },
    { key: 'Approved', value: 'Approved' },
    { key: 'Denied', value: 'Denied' }
];

export const UW_STATUS = [
    { key: 'All', value: 'All' },
    { key: 'PREReject', value: 'P.R.E. Reject' },
    { key: 'InterviewQuestions', value: 'Interview Questions' },
    { key: 'Sold', value: 'Sold' },
    { key: 'BackOff', value: 'Back Off' },
    { key: 'Approved', value: 'Approved' },
    { key: 'Pending', value: 'Pending' },
    { key: 'Rejected', value: 'Rejected' },
    { key: 'DealStructure', value: 'Deal Structure' },
    { key: 'RefersStips', value: 'Refers / Stips' }
];

export const LOCATION_ID = [
    { key: 'INC05', value: 'INC05' },
    { key: 'BDNC02', value: 'BDNC02' },
    { key: 'INC07', value: 'INC07' },
    { key: 'OH105', value: 'OH105' }
];

export const STORE_ID = [
    { key: 'IN101', value: 'IN101' },
    { key: 'BDNC02', value: 'BDNC02' },
    { key: 'INC07', value: 'INC07' },
    { key: 'OH105', value: 'OH105' }
];

export const OLAF_LEAD_SOURCE = ['OLA FULL', 'WEB', 'PPC-PAYPERCLICK']

export const NO_DATA = 'No data'