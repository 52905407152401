import * as actionTypes from "../constants/actionTypes";

const initialState = {
    applicationList: [],
    recordsCount : 0,
    loader: true,
    applicationSearchCriteria: {
        StoreId: '',
        LocId: '',
        FromDate: '',
        ToDate: '',
        LastName: '',
        FirstName: ''
    },
    applicationFilterCriteria: {
        ApplicationStatus: 'All',
        UWStatus: 'All'
    },
    applicationPaginationModel: {
        pageSize: 25,
        page: 0
    },
    applicationSortModel: [
        {
            field: "inStore",
            sort: "desc",
        },
        {
            field: "logDate",
            sort: "desc",
        },
    ]
};

const Applications = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_APPLICATIONDATA_SUCCESS: {
            return { ...state, applicationList: action.payload };
        }
        case actionTypes.GET_APPLICATIONDATA_FAILED: {
            return { ...state, applicationList: initialState };
        }
        case actionTypes.GET_APPLICATIONDATACOUNT_SUCCESS: {
            return { ...state, recordsCount: action.payload };
        }
        case actionTypes.GET_APPLICATIONDATACOUNT_FAILED: {
            return { ...state, applicationList: initialState };
        }
        case actionTypes.SET_APPLICATIONGRID_SPINNER: {
            return { ...state, loader: action.payload };
        }
        case actionTypes.SET_APPLICATIONLIST: {
            return { ...state, applicationList: action.payload };
        } 
        case actionTypes.SET_APPLICATIONSEARCHCRITERIA: {
            return { ...state, applicationSearchCriteria: action.payload };
        }
        case actionTypes.SET_APPLICATIONFILTERCRITERIA: {
            return { ...state, applicationFilterCriteria: action.payload };
        }
        case actionTypes.UPDATE_APPLICATION_PAGINATION_MODEL: {
            return {
                ...state,
                applicationPaginationModel: action.payload
            }
        }
        case actionTypes.UPDATE_APPLICATION_SORT_MODEL: {
            return {
                ...state,
                applicationSortModel: action.payload
            }
        }
        case actionTypes.RESET_APPLICATION_SORT_MODEL: {
            return {
                ...state,
                applicationSortModel: initialState.applicationSortModel
            }
        }
        case actionTypes.LOGOUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};

export default Applications;
