import { GET_REFERRED_BY_RESPONSE_START,
GET_REFERRED_BY_RESPONSE_FAILED,
GET_REFERRED_BY_RESPONSE_SUCCESS } from "../constants/actionTypes";

const initialState = {
    data: {},
    loading: false,
    errors: null,
    open: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REFERRED_BY_RESPONSE_START:
            return {
             ...state,
             loading: true,
             errors: null
           };
         case GET_REFERRED_BY_RESPONSE_FAILED:
            return {
             ...state,
              loading: false,
              errors: action.payload
          };
         case GET_REFERRED_BY_RESPONSE_SUCCESS:
             return {
               ...state,
               loading: false,
               data: action.payload,
               errors: null,
           };
        default:
            return state;
    }
};
