export const getUserDetailsLoc = '/users/userDetails/{0}';
export const getUserLocations = '/users/locations/{0}?isManager={1}';
export const getSalesPersonGoals = '/users/salespersongoals/{0}/{1}';
export const getSalesPersonGoalsProgress = 'Users/getuseractivity/{0}/{1}/{2}';
export const getCrmMetricsPath = '/users/getCrmMetrics/{0}/{1}/{2}';
export const getStorePreferenceValueRoute =
    '/users/storepreference/storeId/{0}/locId/{1}/storePreferenceName/{2}';
export const checkIsCorporateUser = '/users/checkIsCorporateUser/{0}';
export const updateCorpUserHomeLocId = '/users/updateCorpUserHomeLocId/{0}/{1}';
export const getUserSalesPersons = '/users/salespersons/storeId/{0}/locId/{1}';
export const updateLeadBookSharedStatusPath =
    '/users/updateLeadBookSharedStatus';
export const postSalesPersonGoals = '/users/salespersongoals';
export const searchStoreEmployeePath = '/users/searchStoreEmployee/{0}/{1}/{2}/{3}';
export const manageSalespersons = '/users/updateSalesperson';