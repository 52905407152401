import { isNull, isUndefined, map, cloneDeep } from "lodash";
import * as actionTypes from "../constants/actionTypes";

import { HOTLIST_INDEX } from "../constants/WebClientConst";

const initialState = {
    leadCounts: [],
    contactType: {},
    appointmentCounts: [],
    leads: [],
    appointmentLeads: [],
    totalLeadsCount: 0,
    selectedLeadbookTab: 0,
    currentLeadBookCategory: 0,
    currentAppointmentCategory: 0,
    searchText: "",
    searchData: [],
    searchType: 0,
    sortDirection: "",
    sortColumn: "",
    apptSortDirection: "",
    apptSortColumn: "",
    loading: true,
    categoryLoading: true,
    isSharedLeadbook: false,
    buttonSelectStatus: false,
    vehicleLoanDetails: {
        vehicleLoanInfo: [],
        loading: true,
        errors: null,
    },
    leadsForMetricsCategory: {
        leadInfo: [],
        frequency: "",
        salesPersonId: "",
        storeId: "",
        locId: "",
        loading: false,
        errors: null,
    },
    currentLeadTypeSelectedInMetrics: "",
    currentCrmMetricsTabValue: 0,
    leadbookOpenFilter: false,
    leadbookFilterCriteria: {
        pathway: false,
        appStatus: [],
        lastContactDayDiff: "",
        dayFilterCheckList: [],
        hourFilterCheckList: [],
    },
    errors: {
        setLeadCategoryError: null,
    },
    todoFilterCrit: "All",
    leadbookPaginationModel: {
        pageSize: 100,
        page: 0
    },
    leadbookFilterModel: {
        items: [],
    },
    leadbookSortModel: []
};

const _updateHotlist = (lead, custNum, saved) => {
    if (lead.custNum === custNum) {
        lead.onHotList = saved ? !lead.onHotList : lead.onHotList;
    }
    return lead;
};

const _updateFirelist = (lead, custNum, saved) => {
    if (lead.custNum === custNum) {
        lead.onFireList = saved ? !lead.onFireList : lead.onFireList;
    }
    return lead;
};

const _updateMemo = (lead, custNum, memo) => {
    if (lead.custNum === custNum) {
        lead.hasMemo = true;
        lead.customerMemo = memo;
    }

    return lead;
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LEADS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_TODO_FILTER_CRIT:
            return {
                ...state,
                todoFilterCrit: action.payload,
            };
        case actionTypes.SET_CATEGORY_TYPE:
            return {
                ...state,
                currentLeadBookCategory: action.payload,
            };
        case actionTypes.GET_LEADS_COUNTS_START:
            return {
                ...state,
                categoryLoading: true,
            };
        case actionTypes.GET_LEADS_COUNTS:
            return {
                ...state,
                leadCounts: action.payload.leadCounts,
                appointmentCounts: action.payload.appointmentCounts,
                totalLeadsCount: action.payload.totalLeadsCounts,
                categoryLoading: false,
            };
        case actionTypes.SET_LEADS_CATEGORY:
            return {
                ...state,
                leads: action.payload.leads ? action.payload.leads : [],
                currentLeadBookCategory: action.payload.category,
                loading: false,
                errors: {
                    ...state.errors,
                    setLeadCategoryError: null,
                },
            };
        case actionTypes.SET_LEADS_CATEGORY_FAILED:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    setLeadCategoryError: action.payload,
                },
            };
        case actionTypes.REFRESH_HOT_LIST:
            return {
                ...state,
                leads: action.payload.leads,
            };
        case actionTypes.SET_APPOINTMENT_CATEGORY:
            return {
                ...state,
                appointmentLeads: action.payload.appointmentLeads || [],
                currentAppointmentCategory: action.payload.appCategory,
            };
        case actionTypes.TOGGLE_HOTLIST_STATUS:
            const currentCategory = state.currentLeadBookCategory;
            let leads = state.leads;
            if (currentCategory !== HOTLIST_INDEX) {
                leads = map(state.leads, (item) =>
                    _updateHotlist(
                        item,
                        action.payload.custNum,
                        action.payload.saved
                    )
                );
            }
            const appointments = map(state.appointmentLeads, (item) =>
                _updateHotlist(
                    item,
                    action.payload.custNum,
                    action.payload.saved
                )
            );

            return {
                ...state,
                leads: leads,
                appointmentLeads: appointments,
            };
        case actionTypes.TOGGLE_FIRELIST_STATUS:
            const currentLeadCategory = state.currentLeadBookCategory;
            let leadsList = state.leads;
            if (currentLeadCategory !== HOTLIST_INDEX) {
                leadsList = map(state.leads, item =>
                    _updateFirelist(
                        item,
                        action.payload.custNum,
                        action.payload.saved
                    )
                );
            }
            let appointmentCategoryLeads = map(state.appointmentLeads, item =>
                _updateFirelist(
                    item,
                    action.payload.custNum,
                    action.payload.saved
                )
            );

            return {
                ...state,
                leads: leadsList,
                appointmentLeads: appointmentCategoryLeads
            };
        case actionTypes.UPDATE_LEAD_WITH_HASMEMO:
            let newleads = cloneDeep(state.leads) || [];
            let newappts = cloneDeep(state.appointmentLeads) || [];

            if (!isNull(state.leads) && state.leads.length > 0) {
                newleads = map(cloneDeep(state.leads), (item) =>
                    _updateMemo(
                        item,
                        action.payload.custNum,
                        action.payload.customerMemo
                    )
                );
            }

            if (
                !isNull(state.appointmentLeads) &&
                state.appointmentLeads.length > 0 &&
                !isUndefined(state.appointmentLeads)
            ) {
                newappts = map(cloneDeep(state.appointmentLeads), (item) =>
                    _updateMemo(
                        item,
                        action.payload.custNum,
                        action.payload.customerMemo
                    )
                );
            }

            return {
                ...state,
                leads: newleads,
                appointmentLeads: newappts,
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                ...initialState,
            };
        case actionTypes.SEARCH_CUSTOMER:
            return {
                ...state,
                searchText: action.payload.search,
                searchData: action.payload.searchData,
                searchInProgress: false
            };
        case actionTypes.SEARCH_CUSTOMER_INPROGRESS:
            return {
                ...state,
                searchInProgress: true
            };
        case actionTypes.REFERRAL_SEARCH_CUSTOMER:
            return {
                ...state,
                referralSearchText: action.payload.referralSearchText,
                referralSearchData: action.payload.referralSearchData,
            };

        case actionTypes.SORT_LEAD:
            return {
                ...state,
                leads: action.payload.leads,
                sortDirection: action.payload.sortDirection,
                sortColumn: action.payload.sortColumn,
            };

        case actionTypes.SORT_APPOINTMENT:
            return {
                ...state,
                appointmentLeads: action.payload.appointmentLeads,
                apptSortDirection: action.payload.apptSortDirection,
                apptSortColumn: action.payload.apptSortColumn,
            };

        case actionTypes.SET_LEADBOOK_CATEGORY_START:
            return {
                ...state,
                loading: true,
                errors: {
                    ...state.errors,
                    setLeadCategoryError: null,
                },
            };
        case actionTypes.SET_IS_SHARED_LEADBOOK:
            return {
                ...state,
                isSharedLeadbook: action.payload,
            };
        case actionTypes.GET_VEHICLE_LOAN_DETAILS_START:
            return {
                ...state,
                vehicleLoanDetails: {
                    vehicleLoanInfo: [],
                    loading: true,
                    errors: null,
                },
            };
        case actionTypes.GET_VEHICLE_LOAN_DETAILS_SUCCESS:
            return {
                ...state,
                vehicleLoanDetails: {
                    vehicleLoanInfo: action.payload,
                    loading: false,
                    errors: null,
                },
            };
        case actionTypes.GET_VEHICLE_LOAN_DETAILS_FAILED:
            return {
                ...state,
                vehicleLoanDetails: {
                    vehicleLoanInfo: [],
                    loading: false,
                    errors: action.payload,
                },
            };
        case actionTypes.GET_LEADS_FOR_METRICS_CATEGORY_START:
            return {
                ...state,
                leadsForMetricsCategory: {
                    leadInfo: [],
                    frequency: "",
                    salesPersonId: "",
                    storeId: "",
                    locId: "",
                    loading: true,
                    errors: null,
                },
            };
        case actionTypes.GET_LEADS_FOR_METRICS_CATEGORY_SUCCESS:
            return {
                ...state,
                leadsForMetricsCategory: {
                    leadInfo: action.payload.leadInfo,
                    frequency: action.payload.frequency,
                    salesPersonId: action.payload.salesPersonId,
                    storeId: action.payload.storeId,
                    locId: action.payload.locId,
                    loading: false,
                    errors: null,
                },
            };
        case actionTypes.GET_LEADS_FOR_METRICS_CATEGORY_FAILED:
            return {
                ...state,
                leadsForMetricsCategory: {
                    leadInfo: [],
                    frequency: "",
                    salesPersonId: "",
                    storeId: "",
                    locId: "",
                    loading: false,
                    errors: action.payload,
                },
            };
        case actionTypes.SET_CURRENT_LEAD_TYPE_FOR_METRICS:
            return {
                ...state,
                currentLeadTypeSelectedInMetrics: action.payload,
            };
        case actionTypes.SET_CRM_METRICS_TAB_VALUE:
            return {
                ...state,
                currentCrmMetricsTabValue: action.payload,
            };
        case actionTypes.SET_LEADBOOK_FILTER_STATUS:
            return {
                ...state,
                leadbookOpenFilter: action.payload,
            };
        case actionTypes.SET_LEADBOOK_FILTER_CRITERIAS:
            return {
                ...state,
                leadbookFilterCriteria: action.payload,
            };
        case actionTypes.DEACTIVATE_CALLS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DEACTIVATE_CALLS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.DEACTIVATE_CALLS_FAILED:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.SELECT_ALL_REASSIGN_START:
            return {
                ...state,
                leads: [],
            };
        case actionTypes.SELECT_ALL_BUTTON_STATUS:
            return {
                ...state,
                buttonSelectStatus: action.payload,
            };
        case actionTypes.SELECT_ALL_REASSIGN:
            return {
                ...state,
                leads: action.payload.leads,
                appointmentLeads: action.payload.appointmentLeads,
            };
        case actionTypes.SELECT_SINGLE_LEAD_REASSIGN:
            return {
                ...state,
                leads: action.payload.leads,
                appointmentLeads: action.payload.appointmentLeads,
            };
        case actionTypes.CONTACT_PREFERENCE_SUCCESS:
            return {
                ...state,
                contactType: action.payload,
            };
        case actionTypes.SET_SELECTED_LEADBOOK_TAB:
            return {
                ...state,
                selectedLeadbookTab: action.payload,
            }
        case actionTypes.UPDATE_LEADBOOK_PAGINATION_MODEL: {
            return {
                ...state,
                leadbookPaginationModel: action.payload
            }
        }
        case actionTypes.UPDATE_LEADBOOK_FILTER_MODEL: {
            return {
                ...state,
                leadbookFilterModel: action.payload
            }
        }
        case actionTypes.UPDATE_LEADBOOK_SORT_MODEL: {
            return {
                ...state,
                leadbookSortModel: action.payload
            }
        }
        case actionTypes.RESET_LEADBOOK_PAGINATION_FILTER_AND_SORT_MODELS: {
            return {
                ...state,
                leadbookPaginationModel: initialState.leadbookPaginationModel,
                leadbookFilterModel: initialState.leadbookFilterModel,
                leadbookSortModel: initialState.leadbookSortModel
            }
        }
        default:
            return state;
    }
};
