import { get } from 'lodash';
import { ApiClient } from '../ApiClient';
import { usersClient } from '../client/api/users';
import * as _ from 'lodash';
import CookieAuth from '../lib/APIResponse/CookieAuth';
import * as actionTypes from '../constants/actionTypes'

const authActions = {
    login: (username, password = '') => async (dispatch, getState) => {
        //todo: could remove this since login takes care of this
        console.log('username', username);
        //TODO: Call out to authentication api and store the bearer token, and possibly user context, instead of username
        await ApiClient.login(username);

        dispatch({
            type: actionTypes.LOGIN,
            payload: {
                authenticated: true,
                username: username
            }
        });
    },

    googleLogin: (crmAuthResponse) => async (dispatch) => {
        await ApiClient.login(crmAuthResponse.username);

        // Existing code uses camelCaseFor reducer auth keys.
        let userInformation = {
            email: crmAuthResponse.google.userInformation.email,
            givenName: crmAuthResponse.google.userInformation.givenName,
            name: crmAuthResponse.google.userInformation.name,
            familyName: crmAuthResponse.google.userInformation.family_name,
            picture: crmAuthResponse.google.userInformation.picture,
            scope: crmAuthResponse.google.userInformation.scope,
        }

        let tokenResponse = {
            accessToken: crmAuthResponse.google.tokenResponse.access_token,
            refreshToken: crmAuthResponse.google.tokenResponse.refresh_token,
            tokenIdForJwt: crmAuthResponse.google.tokenResponse.id_token,
            tokenType: crmAuthResponse.google.tokenResponse.token_type,
        }

        const payload = {
            authenticated: true,
            role: crmAuthResponse.role,
            email: crmAuthResponse.email,
            username: crmAuthResponse.username,
            ...userInformation,
            ...tokenResponse
        };
        dispatch({
            type: actionTypes.LOGIN,
            payload
        });
    },

    setUserRole: payload => dispatch => {
        dispatch({
            type: actionTypes.SET_USER_ROLE,
            payload
        });
    },

    setIsManager: payload => dispatch => {
        dispatch({
            type: actionTypes.SET_ISMANAGER,
            payload
        });
    },

    googleLogout: payload1 => async dispatch => {
        const payload = { ...payload1, authenticated: false };
        dispatch({
            type: actionTypes.LOGOUT,
            payload
        });
    },

    logout: () => async dispatch => {
        await ApiClient.logout();

        dispatch({
            type: actionTypes.LOGOUT
        });
    },

    getUserDetails: username => async dispatch => {
        const userDetailsResponse = await usersClient.getUserDetails(username);
        const userDetails = get(userDetailsResponse, 'data', {});

        dispatch({
            type: actionTypes.SET_USER_DETAILS,
            payload: userDetails
        });
    },

    checkIsCorporateUser: username => async dispatch => {
        const isCorporateUser = await usersClient.checkIsCorporateUser(username);
        dispatch({
            type: actionTypes.SET_ISCORPORATE_USER,
            payload: isCorporateUser
        });
        if (isCorporateUser) {
            dispatch({
                type: actionTypes.SET_ISMANAGER,
                payload: true
            });
        }
    },

    getUserSalesPersons: (storeId, locId) => async (dispatch, getState) => {
        try {
            if (locId && storeId) {
                const userSalesPersonsResponse = await usersClient.getUserSalesPersons(
                    storeId,
                    locId
                );

                dispatch({
                    type: actionTypes.SET_SALES_PERSONS,
                    payload: userSalesPersonsResponse
                        ? userSalesPersonsResponse.data.messageBody.salesPersons
                        : []
                });

                return userSalesPersonsResponse;
            }
        } catch (error) {
            console.error('User error: ', error);
        }
    },

    setSelectedSalesPerson: selectedSalesPerson => async (
        dispatch,
        getState
    ) => {
        dispatch({
            type: actionTypes.SET_SELECTED_SALES_PERSON,
            payload: selectedSalesPerson
        });
    },

    getStorePreferenceValue: (storeId, locId, storePreferenceName) => async (
        dispatch,
        getState
    ) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            storePreferenceName
        );

        dispatch({
            type: actionTypes.SET_STOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getPREStorePreferenceValue: (
        storeId,
        locId,
        preStorePreferenceName
    ) => async (dispatch, getState) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            preStorePreferenceName
        );

        dispatch({
            type: actionTypes.SET_PRESTOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getTextTabStorePreferenceValue: (storeId, locId, textTabStorePreferenceName) => async (
        dispatch,
        getState
    ) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            textTabStorePreferenceName
        );

        dispatch({
            type: actionTypes.SET_TEXTTABSTOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getCRMCreditBureauViewPreferenceValue: (storeId, locId, CRMCreditBureauViewPreferenceName) => async (
        dispatch,
        getState
    ) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            CRMCreditBureauViewPreferenceName
        );

        dispatch({
            type: actionTypes.SET_CRMCREDITBUREAUVIEWSTOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getShopperProfileTabStorePreferenceValue: (storeId, locId, shopperProfileTabStorePreferenceName) => async (
        dispatch,
        getState
    ) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            shopperProfileTabStorePreferenceName
        );

        dispatch({
            type: actionTypes.SET_SHOPPERPROFILETABSTOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getCoBuyerActionEnabledPreferenceValue: (storeId, locId, coBuyerActionEnabledPreferenceName) => async (
        dispatch,
        getState
    ) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            coBuyerActionEnabledPreferenceName
        );

        dispatch({
            type: actionTypes.SET_COBUYERACTIONSSTOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getCRMViewOlafScorePreferenceValue: (storeId, locId, CrmViewOlafScorePreferenceName) => async (
        dispatch,
        getState
    ) => {
        const storePreferenceValue = await usersClient.getStorePreferenceValue(
            storeId,
            locId,
            CrmViewOlafScorePreferenceName
        );

        dispatch({
            type: actionTypes.SET_CRMVIEWOLAFSCOREPREFERENCE_VALUE,
            payload: storePreferenceValue.data
        });
    },

    getUserLocations: (username, isManager, isCorporateUser) => async (dispatch, getState) => {
        const { auth } = getState();

        const userLocationsResponse = await usersClient.getUserLocations(
            isManager
        );
        const userLocations = get(userLocationsResponse, 'data', {})
            .messageBody;

        const homeLocationInfo = _.find(userLocations, function (l) {
            return l.isHomeLocation === true;
        });
        let homeLocation = '';
        if (homeLocationInfo !== null) {
            homeLocation = _.join(
                [homeLocationInfo.storeId, homeLocationInfo.locId],
                '_'
            );
        } else {
            homeLocation = _.join(
                [auth.userDetails.storeId, auth.userDetails.locationId],
                '_'
            );
        }

        let previousLocation = (isCorporateUser && new CookieAuth().getCookieValue('loggedInUserId') === username && new CookieAuth().getCookieValue('selectedLocId') !== null) ? new CookieAuth().getCookieValue('selectedLocId') : homeLocation;

        dispatch({
            type: actionTypes.SET_USER_STORES,
            payload: userLocations
        });

        dispatch({
            type: actionTypes.SET_USER_LOCATIONS,
            payload: userLocations
        });

        dispatch({
            type: actionTypes.SET_SELECTED_LOCATION,
            payload: previousLocation
        });

        dispatch({
            type: actionTypes.SET_SELECTED_STORE,
            payload: homeLocation
        });

        return homeLocation;
    },

    updateSelectedLocId: storeLoc => dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_LOCATION,
            payload: storeLoc
        });
    },

    updateSelectedStoreId: storeLoc => dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_STORE,
            payload: storeLoc
        });
    },

    updateUserHomeLocId: (userId, homeLocId) => async dispatch => {
        dispatch({
            type: actionTypes.UPDATE_HOME_LOCID_START
        });
        try {
            await usersClient.postUserHomeLocId(userId, homeLocId);
            dispatch({
                type: actionTypes.UPDATE_HOME_LOCID_SUCCESS
            });
        } catch (err) {
            dispatch({
                type: actionTypes.UPDATE_HOME_LOCID_FAILED,
                payload: err.message
            });
        }
    },

    isUserAuthenticated: payload => async (dispatch, getState) => {
        if (!payload.authenticated) {
            dispatch({
                type: actionTypes.LOGOUT
            });
        } else {
            dispatch({
                type: actionTypes.LOGIN,
                payload: {
                    authenticated: true,
                    username: payload.username
                }
            });
        }
    },
};

export default authActions;
