import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack'
import CookiesProvider from 'react-cookie/lib/CookiesProvider';
import isEmpty from 'lodash/isEmpty';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import cookieAuth from './lib/APIResponse/CookieAuth';
import authAction from './actions/auth';
import { ToastProvider } from 'react-toast-notifications/dist/ToastProvider';
import Spinner from './components/Shared/Spinner/Spinner';
import { store, persistor} from "./reducers/configureStore"
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

// it's okay to have this here
// https://mui.com/x/introduction/licensing/#license-key-security
LicenseInfo.setLicenseKey('81aec2f4ce86dbee1a9ca229a2538d3aTz05Mzg2OSxFPTE3NTIwNzEyNDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const theme = createTheme();

const App = React.lazy(() => import('./App'));

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

const rootElement = document.getElementById('root');

//this section is to get the login data and check with googleauth to make sure user is authenticated
//if not authenticated will be redirected to home page else to the right location
let isAuthenticated = false;
const cookieAuthentication = new cookieAuth();
const oldAuthValue = cookieAuthentication.getCookieValue('loggedIn') || false;

new cookieAuthentication.isAuthenticated(history.location.pathname).then(
  response => {
    if (!isEmpty(response.redirectUrl)) {
      history.push(response.redirectUrl);
    }
    isAuthenticated = response.authenticated;

    if (
      String(oldAuthValue) !== String(isAuthenticated) ||
      store.getState().auth.authenticated !== isAuthenticated
    ) {
      store.dispatch(authAction.isUserAuthenticated(response));
    }
  }
);

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ToastProvider autoDismissTimeout={'30000'} placement={'top-center'}>
            <PersistGate loading={null} persistor={persistor}>
                <React.Suspense fallback={<Spinner />}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </React.Suspense>
            </PersistGate>
          </ToastProvider>
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
  rootElement
);
//registerServiceWorker();
//import { unregister as unregisterServiceWorker } from './registerServiceWorker'

unregisterServiceWorker();
