import {
    SET_PROFILE_TAB
} from '../constants/actionTypes';

const tabReducer = (state = 0, action) => {
    switch (action.type) {
        case SET_PROFILE_TAB:
            return action.payload;
        default:
            return state;
    }
};

export  { tabReducer };
