import * as actionTypes from '../constants/actionTypes'
import { DONE, CURRENT, NOT_DONE } from '../constants/CallStatus';

const initialState = {
  scheduledDate: '', // from API
  leadType: '', // from API
  leadTypeCode: '',
  contactAttempts: 0, // from API
  lastContacted: '', // from API
  status: '', // from API
  categoryType: '', // from API value 2
  disabled: true,
  firstName: '', // from API
  lastName: '', // from API
  middleName: '',
  email: '', // from API
  zipCode: '',
  city: '',
  suffix: '',
  labelWidth: 0,
  phoneNumberDetails: [],
  checked: true,
  track: '',
  address: '',
  ssn: '',
  applicationType: 'Individual',
  adSourceCategory: '',
  adSourceDetailDescription: '',
  adSourceDetailCode: '',
  saNum: 0,
  decisionStatus:false,
  appointmentDetails: {
    noteDescription: ''
  },
  trackStatus: [
    { name: 'Appointment', trackStatus: DONE },
    { name: 'STIPs', trackStatus: DONE },
    { name: 'PRE', trackStatus: CURRENT },
    { name: 'Application', trackStatus: NOT_DONE },
    { name: 'Decisioned', trackStatus: NOT_DONE },
    { name: 'Sold', trackStatus: NOT_DONE }
  ],
  history: {
    communication: []
  },
  systemNotes: {
    notes: [],
    loading: true,
    errors: null
  },
  reversionDate: null,
  loading: false,
  errors: null,
  olaf: {
	  data: [],
	  errors: null
    },
  resubmit: {
     loading: false,
     error: null,
     status: false
  }
};

const Buyer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_LOADING:
        return { ...state, loading: true }
    case actionTypes.CLEAR_PROFILE:
      return initialState;
    case actionTypes.SET_CUSTOMER_PROFILE:
      return { ...state, ...action.payload, loading: false };
    case actionTypes.RESET_CUSTOMER_PROFILE:
      //const obj = { ...initialState, updateResponse: {} };
      return { ...initialState, updateResponse: {} };
    case actionTypes.SET_CUSTOMER_PROFILE_UPDATE_RESPONSE:
      return { ...state, updateResponse: action.payload };
    case actionTypes.LOADING_IN_PROGRESS:
      return { ...state, updateResponse: {} };
    case actionTypes.SET_CUSTOMER_CALL_ACTION_HISTORY_RESPONSE:
      return { ...state, callActionHistoryResponse: action.payload };
    case actionTypes.SET_CUSTOMER_CALL_ACTION_HISTORY_LIST_RESPONSE:
      return { ...state, history: { communication: action.payload } };
    case actionTypes.CUSTOMER_APPOINTMENT_STATUS:
      return {
        ...state,
        appointmentResponse: action.payload
      };
    case actionTypes.CUSTOMER_APPOINTMENT_NOTE_UPDATE:
      return {
        ...state,
        appointmentDetails: {
          ...state.appointmentDetails,
          noteDescription: action.payload
        }
      };
    case actionTypes.CUSTOMER_APPOINTMENT_TIME_UPDATE:
      return {
        ...state,
        appointmentDetails: {
          ...state.appointmentDetails,
          appoinmentDate: action.payload
        }
      };
    case actionTypes.CUSTOMER_APPOINTMENT_CANCEL:
      return {
        ...state,
        appointmentDetails: {
          ...state.appointmentDetails,
          appoinmentDate: action.payload
        }
      };
    case actionTypes.GET_SYSTEM_NOTES_START:
      return {
        ...state,
        systemNotes: {
          notes: [],
          loading: true,
          errors: null
        }
      };
    case actionTypes.GET_SYSTEM_NOTES_SUCCESS:
      return {
        ...state,
        systemNotes: {
          notes: action.payload,
          loading: false,
          errors: null
        }
      };
    case actionTypes.GET_SYSTEM_NOTES_FAILED:
      return {
        ...state,
        systemNotes: {
          notes: [],
          loading: false,
          errors: action.payload
        }
      };
    case actionTypes.REVERT_LEAD_START:
      return {
        ...state,
        loading: true,
        errors: null
      };
    case actionTypes.REVERT_LEAD_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case actionTypes.REVERT_LEAD_SUCCESS:
      return {
        ...state,
        reversionDate: action.payload,
        loading: false,
        errors: null
      };
    case actionTypes.REVERT_LEAD_TODAY_SUCCESS:
        return {
            ...state,
            reversionDate: null,
            canRevert: false,
            loading: false,
            errors: null,
            leadCategoryCd: 2
        }
    case actionTypes.DELETE_REVERT_LEAD_SUCCESS:
      return {
        ...state,
        reversionDate: null,
        loading: false,
        errors: null
	  };
	case actionTypes.GET_OLAF_APPLICATION:
		return {
			...state,
			olaf: {
				data: action.payload,
				errors: null
			}
		};
    case actionTypes.GET_OLAF_APPLICATION_FAILED:
          return {
              ...state,
              olaf: {
                  data: [],
                  errors: action.payload
              }
          };
    case actionTypes.RESUBMIT_TO_QUEUE_START:
          return {
              ...state,
              resubmit: {
                  loading: true,
                  error: null
              }
          }
    case actionTypes.RESUBMIT_TO_QUEUE_SUCCESS:
          return {
              ...state,
              resubmit: {
                  loading: false,
                  error: null,
                  status: false
              }
          };
    case actionTypes.RESUBMIT_TO_QUEUE_FAILED:
          return {
              ...state,
              resubmit: {
                  loading: false,
                  error: true
              }
          };
    case actionTypes.GET_UW_STATUS_SUCCESS:
          return {
              ...state,
              resubmit: {
                  ...state.resubmit,
                  status: action.payload
              }
          };
    default:
      return state;
  }
};

export default Buyer;
