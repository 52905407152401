import React from 'react';
import './spinnerStyles.scss';

const Spinner = ({
  height = '100vh',
  width = '100%',
  spinnerHeight = '40px',
  spinnerWidth = '40px',
  justify = 'center'
}) => {
  return (
    <div
      className="container"
      style={{ height, width, justifyContent: justify }}
    >
      <div
        className="sk-chase"
        style={{ width: spinnerWidth, height: spinnerHeight }}
      >
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};

export default Spinner;
