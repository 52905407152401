import {
    ACTIVE_NOTIFICATIONS,
    NOTIFICATIONS,
    DELETE_NOTIFICATION,
    TEXTNOTIFICATIONS,
    DELETE_TEXTNOTIFICATIONS,
    UNREAD_TEXTNOTIFICATIONS,
    READ_TEXTNOTIFICATIONS,
    TEXTNOTIFICATIONS_FORMANAGER,
    SET_IS_TEXT_INBOX_OPEN,
    SET_IS_NOTIFICATION_INBOX_OPEN,
    LOGOUT
} from '../constants/actionTypes';

const initialState = {
    events: [],
    isTextInboxOpen: false,
    isNotificationInboxOpen: false,
};

const NotificationEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_NOTIFICATIONS:
            return {
                ...state,
                activeMessages: action.payload
            };
        case NOTIFICATIONS:
            return {
                ...state,
                messages: action.payload
            };
        case TEXTNOTIFICATIONS:
            return {
                ...state,
                textMessages: action.payload
            };
        case TEXTNOTIFICATIONS_FORMANAGER:
            return {
                ...state,
                textMessagesForManager: action.payload
            };
        case DELETE_NOTIFICATION:
            return {
                ...state,
                notificationDeleted: {
                    deleteSuccess: action.payload
                }
            };
        case DELETE_TEXTNOTIFICATIONS:
            return {
                ...state,
                textNotificationsDeleted: {
                    textDeleteSuccess: action.payload
                }
            };
        case UNREAD_TEXTNOTIFICATIONS:
            return {
                ...state,
                unReadTextNotifications: {
                    textUnReadSuccess: action.payload
                }
            };
        case READ_TEXTNOTIFICATIONS:
            return {
                ...state,
                readTextNotifications: {
                    textReadSuccess: action.payload
                }
            };
        case LOGOUT:
            return {
                ...state,
                ...initialState
            };
        case SET_IS_TEXT_INBOX_OPEN: {
            return { ...state, isTextInboxOpen: action.payload };
        }
        case SET_IS_NOTIFICATION_INBOX_OPEN: {
            return { ...state, isNotificationInboxOpen: action.payload };
        }
        default:
            return state;
    }
};

export default NotificationEventsReducer;
