import { isNull, isUndefined } from 'lodash';
import Cookies from 'universal-cookie';
import axios from 'axios';
import format from 'string-format';
import { ApiClient } from '../../../src/ApiClient';
import { getEnvironment } from '../../../src/utilities/environment';
import {
    GOOGLE_ACCESS_TOKEN,
    AUTOMATION_TOKEN,
} from '../../constants/AuthTokenNames';
class CookieAuth {
    constructor() {
        const cookies = new Cookies();
        //get cookie value
        this.accessToken = cookies.get(GOOGLE_ACCESS_TOKEN);
        this.googleId = cookies.get('googleId');
        this.loggedIn = cookies.get('loggedIn');
        this.automationToken = cookies.get(AUTOMATION_TOKEN);
    }

    _shouldReValidateToken = () => {
        if ((!isNull(this.accessToken) && !isUndefined(this.accessToken)) || (!isNull(this.automationToken) && this.automationToken)) {
            return true;
        }
        return false;
    };

    _getUrlToRedirectTo = (isAuthenticated, currentLocation) => {
        let url = '';
        if (isAuthenticated === 'false' || !isAuthenticated) {
            url = '/';
        }
        if (isAuthenticated === 'true' || isAuthenticated) {
            if (currentLocation === '/') {
                url = '/dashboard';
            }
        }
        return url;
    };

    _getValidUserFromGoogle = () => {
        const client = axios.create();
        const url = format(
            'https://www.googleapis.com/oauth2/v1/tokeninfo?access_token={0}',
            this.accessToken
        );
        const request = client.get(url);
        // return request.data.user_id === googleId;
        return request.then(response => {
            const userId = response.data.user_id;
            const email = response.data.email;
            return { userId: userId, email: email };
        });
    };

    _getActiveUserFromAutomation = () => {
        if (isNull(this.automationToken)) return false;
        const env = getEnvironment();
        if ((env == "qa" || env == "debug") && this.automationToken) {
            return true;
        }
        return false;
    }

    isAuthenticated = async currentLocation => {
        const _googleId = this.googleId;
        let payload = { authenticated: false, username: '', redirectUrl: '/' };
        let loggedIn = false;
        let username = "";
        if (this._shouldReValidateToken()) {
            if (this._getActiveUserFromAutomation()){
                loggedIn = true;
                username = _googleId;
            }
            else
            {
                let authUser = await this._getValidUserFromGoogle();
                loggedIn = authUser.userId === _googleId;
                username = authUser.email.split('@')[0];
            }
            const redirectUrl = this._getUrlToRedirectTo(
                loggedIn,
                currentLocation
            );
            if (loggedIn) {
                await ApiClient.login(username);
                payload = {
                    authenticated: loggedIn,
                    username: username,
                    redirectUrl: redirectUrl
                };
            } else {
                await ApiClient.logout();
            }
        }
        this._loggedInStatus(loggedIn);

        return payload;
    };

    // Getter
    get shouldHydrate() {
        return this.loggedIn;
    }

    _loggedInStatus(value) {
        this.setCookieValue('loggedIn', value, { expires: 0 });
        this.loggedIn = value;
    }

    setCookieValue(propertyName, value, cookieSetOptions) {
        const cookies = new Cookies();

        cookies.set(propertyName, value, cookieSetOptions);
    }

    getCookieValue(propertyName) {
        const cookies = new Cookies();

        return cookies.get(propertyName);
    }

    removeCookieValue(propertyName) {
        const cookies = new Cookies();
        cookies.remove(propertyName, { path: '/' });
    }
}

export default CookieAuth;
