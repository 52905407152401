import * as _ from 'lodash';
import * as actionTypes from '../constants/actionTypes'
import { REMINDER, MEMO } from '../constants/Journal';
const toggleInitialState = false;

export const memoControllerReducer = (state = toggleInitialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_EDIT_MEMO:
      return action.payload.editToggle;
    case actionTypes.SET_CUSTOMER_CALL_ACTION_HISTORY_LIST_RESPONSE:
      return toggleInitialState;
    case actionTypes.TOGGLE_EDIT_REMINDER:
      return false;
    case actionTypes.SET_JOURNAL_TYPE:
      return false;
    default:
      return state;
  }
};

export const reminderEditControllerReducer = (state = [], action) => {
  let newState = [];
  if (_.isArray(state)) {
    newState = [...state];
  }
  switch (action.type) {
    case actionTypes.TOGGLE_EDIT_REMINDER:
      newState = newState.map(item => false);
      newState[action.payload.index] = action.payload.toggle;
      return newState;
    case actionTypes.REMINDER_HISTORY_LIST_RESPONSE:
      let editControlList = new Array(action.payload.length);
      editControlList = editControlList.map(item => false);
      return [...editControlList];
    case actionTypes.TOGGLE_EDIT_MEMO:
      newState = newState.map(item => false);
      return newState;
    case actionTypes.SET_JOURNAL_TYPE:
      newState = newState.map(item => false);
      return newState;
    default:
      return newState;
  }
};

export const reminderListReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.REMINDER_HISTORY_LIST_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  memo: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_MEMO:
        let setMemoClone = _.cloneDeep(state.memo)
      _.pullAllBy(setMemoClone, action.payload, 'custNum');
      return {
        memo: setMemoClone.concat(action.payload)
      };
    case actionTypes.UPDATE_CUSTOMER_MEMO:
        let updateMemoClone = _.cloneDeep(state.memo)
      var itemToRemove = _.remove(updateMemoClone, function (item) {
        return item.custNum === action.payload.custNum;
      });

      _.pullAllBy(updateMemoClone, itemToRemove, 'custNum');

      return {
        memo: [...state.memo, action.payload]
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export const selectedActionTabReducer = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTION_TAB_SELECTED:
      if ('payload' in action && !_.isUndefined(action.payload)) {
        return action.payload;
      }
      return state;
    case actionTypes.TOGGLE_EDIT_REMINDER:
      if ('payload' in action && !_.isUndefined(action.payload.toggle)) {
        if (action.payload.toggle === true) {
          return action.payload.tabIndex;
        }
      }
      return state;
    case actionTypes.TOGGLE_EDIT_MEMO:
      if ('payload' in action && !_.isUndefined(action.payload)) {
        if (action.payload.editToggle === true) {
          return action.payload.tabIndex;
        }
      }
      return state;
    default:
      return state;
  }
};

export const journalType = (state = '', action) => {
  switch (action.type) {
    case actionTypes.SET_JOURNAL_TYPE:
      if ('payload' in action && !_.isUndefined(action.payload)) {
        return action.payload;
      }
      return state;
    case actionTypes.TOGGLE_EDIT_REMINDER:
      if ('payload' in action && !_.isUndefined(action.payload.toggle)) {
        if (action.payload.toggle === true) {
          return REMINDER;
        }
        return '';
      }
      return state;
    case actionTypes.TOGGLE_EDIT_MEMO:
      if ('payload' in action && !_.isUndefined(action.payload)) {
        if (action.payload.editToggle === true) {
          return MEMO;
        } else {
          return '';
        }
      }
      return state;
    default:
      return state;
  }
};

export const memoTabIndexReducer = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.SET_MEMO_TAB_INDEX:
      if ('payload' in action && !_.isUndefined(action.payload)) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};
