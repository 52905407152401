import * as actionTypes from "../constants/actionTypes";

const initialState = {
    data: [{ callsMade: 0, appsTaken: 0, salesMade: 0 }],
    salesPersonGoals: [],
    leadsData: [],
    salesPersons: [],
    isEdit: false,
    exportList: [],
    loading: {
        topLevelMetricsLoading: false,
        managerSPGoalsLoading: false,
        postGoalsLoading: false,
        unassignedLeadsLoading: true,
        getSalesPersonGoalItemsLoading: true,
    },
    errors: {
        topLevelMetricsErrors: null,
        managerSPGoalsErrors: null,
        postGoalsErrors: null,
        unassignedLeadsErrors: null,
        getSalesPersonGoalItemsError: null,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_GOALS_SUCCESS:
            return {
                ...state,
                isEdit: false,
                loading: {
                    ...state.loading,
                    postGoalsLoading: false,
                },
                errors: {
                    ...state.errors,
                    postGoalsErrors: null,
                },
            };
        case actionTypes.POST_GOALS_FAILED:
            return {
                ...state,
                isEdit: false,
                loading: {
                    ...state.loading,
                    postGoalsLoading: false,
                },
                errors: {
                    ...state.errors,
                    postGoalsErrors: action.payload,
                },
            };
        case actionTypes.START_GOALS_EDIT:
            return {
                ...state,
                isEdit: true,
            };
        case actionTypes.CANCEL_GOALS_EDIT:
            return {
                ...state,
                isEdit: false,
                salesPersonGoals: [],
            };
        case actionTypes.GET_TOP_LEVEL_METRICS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    topLevelMetricsLoading: true,
                },
            };
        case actionTypes.GET_TOP_LEVEL_METRICS_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    topLevelMetricsLoading: false,
                },
                data: action.payload.data,
                errors: {
                    ...state.errors,
                    topLevelMetricsErrors: null,
                },
            };
        case actionTypes.GET_TOP_LEVEL_METRICS_FAILED:
            return {
                ...state,
                loading: { ...state.loading, topLevelMetricsLoading: false },
                errors: {
                    ...state.errors,
                    topLevelMetricsErrors: action.payload,
                },
            };
        case actionTypes.GET_UNASSIGNED_LEADS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    unassignedLeadsLoading: true,
                },
            };
        case actionTypes.GET_UNASSIGNED_LEADS_SUCCESS:
            return {
                ...state,
                leadsData: action.payload,
                loading: {
                    ...state.loading,
                    unassignedLeadsLoading: false,
                },
                errors: {
                    ...state.errors,
                    unassignedLeadsErrors: null,
                },
            };
        case actionTypes.GET_UNASSIGNED_LEADS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    unassignedLeadsLoading: false,
                },
                errors: {
                    ...state.errors,
                    unassignedLeadsErrors: action.payload,
                },
            };
        case actionTypes.POST_UNASSIGNED_LEADS_SUCCESS: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    unassignedLeadsLoading: false,
                },
                errors: {
                    ...state.errors,
                    unassignedLeadsErrors: "",
                },
            };
        }
        case actionTypes.POST_UNASSIGNED_LEADS_FAILED:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    unassignedLeadsErrors: action.payload,
                },
            };
        case actionTypes.GET_SALES_PERSON_GOAL_ITEMS_START:
            return {
                ...state,
                salesPersonGoals: [],
                loading: {
                    ...state.loading,
                    getSalesPersonGoalItemsLoading: true,
                },
            };
        case actionTypes.GET_SALES_PERSON_GOAL_ITEMS_SUCCESS:
            return {
                ...state,
                salesPersonGoals: action.payload,
                loading: {
                    ...state.loading,
                    getSalesPersonGoalItemsLoading: false,
                },
                errors: {
                    ...state.errors,
                    getSalesPersonGoalItemsError: null,
                    postGoalsErrors: null,
                },
            };
        case actionTypes.GET_SALES_PERSON_GOAL_ITEMS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getSalesPersonGoalItemsLoading: false,
                },
                errors: {
                    ...state.errors,
                    getSalesPersonGoalItemsError: action.payload,
                },
            };
        case actionTypes.LEAD_EXPORT_SUCCESS:
            return {
                ...state,
                exportList: action.payload,
            };
        case actionTypes.CANCEL_EXPORT_DATA:
            return {
                ...state,
                exportList: action.payload,
            };
        default:
            return state;
    }
};
