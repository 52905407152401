import * as actionTypes from '../constants/actionTypes'
import { isUndefined } from 'lodash';
import { REHYDRATE } from 'redux-persist';
import cookieAuth from '../lib/APIResponse/CookieAuth';

const initialState = {
    authenticated: false,
    username: '',
    userDetails: '',
    userLocations: '',
    salesPersons: [],
    selectedSalesPerson: 'all__all',
    selectedStoreLoc: '',
    googleId: '', //check if needed
    imageUrl: '',
    email: '',
    name: '',
    givenName: '',
    familyName: '',
    accessToken: '',
    tokenExpiresIn: 0,
    tokenIdForJwt: '',
    role: '',
    isManager: false,
    isCorporateUser: false,
    userStores: '',
    selectedStore: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.SET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload
            };
        case actionTypes.SET_USER_LOCATIONS:
            return {
                ...state,
                userLocations: action.payload
            };
        case actionTypes.SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedStoreLoc: action.payload
            };
        case actionTypes.SET_SALES_PERSONS:
            return {
                ...state,
                salesPersons: action.payload
            };
        case actionTypes.SET_SELECTED_SALES_PERSON:
            return {
                ...state,
                selectedSalesPerson: action.payload
            };
        case actionTypes.SET_STOREPREFERENCE_VALUE:
            return {
                ...state,
                storePreferenceValue: action.payload
            };
        case actionTypes.SET_PRESTOREPREFERENCE_VALUE:
            return {
                ...state,
                preStorePreferenceValue: action.payload
            };
        case actionTypes.SET_TEXTTABSTOREPREFERENCE_VALUE:
            return {
                ...state,
                textTabStorePreferenceValue: action.payload
            };
        case actionTypes.SET_CRMCREDITBUREAUVIEWSTOREPREFERENCE_VALUE:
            return {
                ...state,
                crmViewCreditBureauStorePreferenceValue: action.payload
            };
        case actionTypes.SET_COBUYERACTIONSSTOREPREFERENCE_VALUE:
            return {
                ...state,
                coBuyerActionStorePreferenceValue: action.payload
            };
        case actionTypes.SET_SHOPPERPROFILETABSTOREPREFERENCE_VALUE:
            return {
                ...state,
                shopperProfileTabStorePreferenceValue: action.payload
            };
        case actionTypes.SET_CRMVIEWOLAFSCOREPREFERENCE_VALUE:
            return {
                ...state,
                crmViewOlafScorePreferenceValue: action.payload
            };
        case actionTypes.SET_USER_ROLE:
            return {
                ...state,
                role: action.payload
            };
        case actionTypes.SET_ISMANAGER:
            return {
                ...state,
                isManager: action.payload
            };
        case actionTypes.SET_ISCORPORATE_USER:
            return {
                ...state,
                isCorporateUser: action.payload
            };
        case actionTypes.LOGOUT:
            return { ...state, ...initialState };
        case actionTypes.USER_AUTHENTICATED:
            return { ...state, reauth: action.payload };
        case REHYDRATE:
            const copied = action.payload;
            const shouldHydrate = new cookieAuth().shouldHydrate;
            let newState = state;
            if (shouldHydrate === 'true' && !isUndefined(copied)) {
                newState = copied.auth;
            }
            return {
                ...newState
            };
        case actionTypes.SET_USER_STORES:
            return {
                ...state,
                userStores: action.payload
            };
        case actionTypes.SET_SELECTED_STORE:
            return {
                ...state,
                selectedStore: action.payload
            };
        default:
            return state;
    }
};
