import * as actionTypes from '../constants/actionTypes'

const initialState = {
    data: [],
    loading: false,
    errors: null,
    open: false,
	isLeadBookShared: false,
	crmMetrics: {}
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SALES_PERSON_GOALS_OPEN:
            return {
                ...state,
                open: true
            };
        case actionTypes.GET_SALES_PERSON_GOALS_CLOSE:
            return {
                ...state,
                open: false
            };
        case actionTypes.GET_SALES_PERSON_GOALS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_SALES_PERSON_GOALS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.userActivityMetrics,
                isLeadBookShared: action.payload.isLeadBookShared,
                errors: null
            };
        case actionTypes.GET_SALES_PERSON_GOALS_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload
			};
		case actionTypes.GET_CRM_METRICS_GOALS_START:
			return {
				...state, 
				crmMetrics: {}
			}
		case actionTypes.GET_CRM_METRICS_GOALS_SUCCESS:
			return {
				...state, 
				crmMetrics: action.payload
			}
		case actionTypes.GET_CRM_METRICS_GOALS_FAILED:
			return {
				...state, 
				crmMetrics: {}
			}
        default:
            return state;
    }
};
