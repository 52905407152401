import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import * as Auth from './auth';
import * as Leads from './leads';
import * as Journal from './journal';
import * as Referral from './referral';
import * as Texting from './texting';
import buyer from './buyer';
import NotificationEventsReducer from './eventsReducer';
import cobuyer from './cobuyer';
import progressReducer from './progressReducer';
import { tabReducer } from './profileReducer';
import { stipListReducer } from './stipListReducer';
import * as Manager from './manager';
import * as SalesPerson from './salesPerson';
import Applications from './application';
import * as Metadata from './metadata';
import * as Loader from './loader';
import shopperProfile from './shopperProfile';

export const history = createBrowserHistory();

const reducers = {
    auth: Auth.reducer,
    leads: Leads.reducer,
    texting:Texting.reducer,
    buyer,
    cobuyer,
    progressReducer,
    notification: NotificationEventsReducer,
    memoEditToggle: Journal.memoControllerReducer,
    journal: Journal.reducer,
    selectedActionTab: Journal.selectedActionTabReducer,
    reminderEditToggle: Journal.reminderEditControllerReducer,
    reminders: Journal.reminderListReducer,
    profileTab: tabReducer,
    journalType: Journal.journalType,
    stipsList: stipListReducer,
    manager: Manager.reducer,
    salesPerson: SalesPerson.reducer,
    referral: Referral.reducer,
    Applications,
    memoTabIndex: Journal.memoTabIndexReducer,
    metadata: Metadata.reducer,
    loader: Loader.reducer,
    shopperProfile
  };

  const persistConfig = {
    key: 'root',
    storage
  }

  const rootReducer = (history) => combineReducers({
      router: connectRouter(history),
      ...reducers
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }
    }).concat(routerMiddleware(history))
  });

  export const persistor = persistStore(store);