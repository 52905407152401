import format from 'string-format';

import getClient from '../client';
import * as apiPaths from '../paths/users';
import { userName } from '../../ApiClient';

const baseUrl = '/api';

const client = getClient(baseUrl);

const usersClient = {
    async getUserDetails() {
        const results = await client.get(
            format(apiPaths.getUserDetailsLoc, userName)
        );
        return results;
    },

    async getUserLocations(isManager) {
        if (!userName) {
            return null;
        }

        const results = await client.get(
            format(apiPaths.getUserLocations, userName, isManager)
        );
        return results;
    },

    async getSalesPersonGoals(storeId, locId) {
        const results = await client.get(
            format(apiPaths.getSalesPersonGoals, storeId, locId)
        );
        return results;
    },

    async getSalesPersonGoalsProgress(salesPerson, storeId, locId) {
        const results = await client.get(
            format(
                apiPaths.getSalesPersonGoalsProgress,
                salesPerson,
                storeId,
                locId
            )
        );
        return results;
    },

    async getCRMMetrics(userId, storeId, locId) {
        const results = await client.get(
            format(apiPaths.getCrmMetricsPath, userId, storeId, locId)
        );
        return results;
    },

    async getStorePreferenceValue(storeId, locId, storePreferenceName) {
        const results = await client.get(
            format(
                apiPaths.getStorePreferenceValueRoute,
                storeId,
                locId,
                storePreferenceName
            )
        );
        return results;
    },

    async checkIsCorporateUser(userName) {
        if (!userName) {
            return null;
        }
        const results = await client.get(
            format(apiPaths.checkIsCorporateUser, userName)
        );
        if (results && results.data) return results.data;
    },

    async postUserHomeLocId(userId, homeLocId) {
        return await client.post(
            format(apiPaths.updateCorpUserHomeLocId, userId, homeLocId)
        );
    },

    async getUserSalesPersons(storeId, locId) {
        const results = await client.get(
            format(apiPaths.getUserSalesPersons, storeId, locId)
        );
        return results;
    },

    async updateLeadBookSharedStatus(
        userId,
        storeId,
        locationId,
        isLeadbookShared
    ) {
        const payload = {
            userId,
            storeId,
            locationId,
            isLeadbookShared
        };
        return await client.post(
            apiPaths.updateLeadBookSharedStatusPath,
            payload
        );
    },

    async postSPGoals(data) {
        return await client.post(apiPaths.postSalesPersonGoals, data);
    },

    async searchStoreEmployee(storeId, locId, searchText, loggedInUserId) {
        const results = await client.get(
            format(apiPaths.searchStoreEmployeePath, storeId, locId, searchText, loggedInUserId)
        );
        return results;
    },

    async manageSalespersons(data) {
        return await client.post(apiPaths.manageSalespersons, data);
    }
};

export { usersClient };
