import { SET_TEXT_HISTORY, ADD_MESSAGE_TO_TEXT_HISTORY, CLEAR_TEXT_HISTORY, TEXT_NOTIFICATION_SUBSCRIPTION } from "../constants/actionTypes";
import { TextHistoryManager } from "../utilities/textHistoryCacheManager";
const initialState = {
    textHistory: [],
    notificationSubscriptionStatus: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEXT_HISTORY: {
            let manager = new TextHistoryManager(action.payload.custNum, action.payload.textHistory)
            let cacheCombinedTextHistory = manager.getCacheCombinedTextHistory();
            return { ...state, textHistory: cacheCombinedTextHistory };
        }
        case ADD_MESSAGE_TO_TEXT_HISTORY: {
            let manager = new TextHistoryManager(action.payload.custNum, action.payload.textHistory)
            manager.addOutGoingMessageToCache(action.payload.message)
            let cacheCombinedTextHistory = manager.getCacheCombinedTextHistory()
            return { ...state, textHistory: cacheCombinedTextHistory };
        }
        case CLEAR_TEXT_HISTORY: {
            return { ...state, textHistory: [] };
        }
        case TEXT_NOTIFICATION_SUBSCRIPTION: {
            return { ...state, notificationSubscriptionStatus: action.payload };
        }
        default:
            return state;
    }
};
