
import { format, addMinutes } from 'date-fns'
import Cookies from 'universal-cookie';

const TEXT_HISTORY = 'textHistory'

class TextHistoryCacheManager {
    constructor(customerNumber) {
        this.customerNumber = customerNumber
        const cookies = new Cookies();
        const cachedTextHistory = cookies.get(TEXT_HISTORY);
        try {
            this.cachedTextHistory = cachedTextHistory != undefined ? JSON.parse(decodeURI(cachedTextHistory)) : {};
        } catch (error) {
            console.error("Error in parsing to JSON object. Cached text history: " + cachedTextHistory)
            this.cachedTextHistory = {}
        }
        this.customerCachedMessages = this.cachedTextHistory[this.customerNumber.toString()] ? this.cachedTextHistory[customerNumber] : []
    }

    addMessage(messageObject) {
        this.customerCachedMessages.push(messageObject)
        this.updateCookiesForTextHistory()
    }
    updateCookiesForTextHistory() {
        this.cachedTextHistory[this.customerNumber.toString()] = this.customerCachedMessages

        const cookies = new Cookies();
        cookies.set(TEXT_HISTORY, encodeURI(JSON.stringify(this.cachedTextHistory)), {
            path: "/",
        })
    }

    getMessagesAfterTime(dateTime) {
        if (dateTime) {
            dateTime = Date.parse(dateTime)
            return this.customerCachedMessages.filter((message) => {
                return Date.parse(message.createdUtc) > dateTime
            })
        }
        return this.customerCachedMessages
    }
    removeMessagesBeforeTime(dateTime) {
        if (dateTime) {
            dateTime = Date.parse(dateTime)
            this.customerCachedMessages = this.customerCachedMessages.filter((message) => {
                let messageCreatedUtc = Date.parse(message.createdUtc)
                return messageCreatedUtc > dateTime
            })
        }
        this.updateCookiesForTextHistory()
    }

}


class TextHistoryManager {
    constructor(customerNumber, messagesFromS3Document = []) {
        this.customerNumber = customerNumber
        this.messagesFromS3Document = Array.isArray(messagesFromS3Document) ? messagesFromS3Document : []
        this.textHistoryCacheManager = new TextHistoryCacheManager(customerNumber);
    }


    addOutGoingMessageToCache(message) {
        let date = new Date()
        let messageObject = {
            "sendingUserId": message.salespersonUserId,
            "direction": "out",
            "createdUtc": format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss'),
            "text": message.text,
            "status": "sending"
        }
        this.textHistoryCacheManager.addMessage(messageObject)
        console.log(messageObject)
    }
    getLastS3DocumentTextMessage() {
        if (this.messagesFromS3Document.length > 0) {
            return this.messagesFromS3Document.slice(-1).pop().createdUtc
        }
    }
    getCacheCombinedTextHistory() {
        let lastMessageTime = this.getLastS3DocumentTextMessage();
        let messages = this.textHistoryCacheManager.getMessagesAfterTime(lastMessageTime)
        this.textHistoryCacheManager.removeMessagesBeforeTime(lastMessageTime)
        return this.messagesFromS3Document.concat(messages)
    }
}

export {
    TextHistoryManager
};

