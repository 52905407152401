import {
    GET_CRM_VERSION,
    GET_LEAD_VERSION
} from "../constants/actionTypes";

const initialState = {
    crmVersion: "0.0.0.0",
    leadVersion: "0.0.0.0"
};

export const reducer = (state = initialState, action) =>
{
    switch (action.type) {
        case GET_CRM_VERSION:
            return {
                ...state,
                crmVersion: action.payload
            };
        case GET_LEAD_VERSION:
            return {
                ...state,
                leadVersion: action.payload
            };
        default:
            return state;
    }
};
