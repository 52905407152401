import * as actionTypes from '../constants/actionTypes'

const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADER:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

