import * as actionTypes from "../constants/actionTypes";

const initialState = {
    isFetchingData: true,
    noDataForUser: false,
    traits: null,
    events: null,
    vehicles: null,
};

const shopperProfile = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_FETCHING_DATA:
            return {
                ...state,
                isFetchingData: action.payload
            };
        case actionTypes.GET_TRAITS:
            return {
                ...state,
                traits: action.payload
            };
        case actionTypes.GET_EVENTS:
            return {
                ...state,
                events: action.payload
            };
        case actionTypes.GET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload
            };
        case actionTypes.NO_DATA_FOR_USER:
            return {
                ...state,
                noDataForUser: action.payload
            };
        // Handle other actions as needed
        default:
            return state;
    }
};

export default shopperProfile;